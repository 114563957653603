import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../../styles/Navbar.css';
import { Corporate_url } from '../../Config/Config';
const Navbar = () => {
  const [userDetails, setUserDetails] = useState(null);

  const fetchUserDetails = async () => {
    try {
      const token = localStorage.getItem('token');
      const userId = localStorage.getItem('user_id');

      if (userId && token) {
        const response = await axios.get(`${Corporate_url}/api/user-details/${Number(userId)}`, {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });
        setUserDetails(response.data);
      }
    } catch (error) {
      console.error('Error fetching user details:', error);
    }
  };
  useEffect(() => {
    fetchUserDetails();
  }, []); // Only run once on mount

  return (
    <div className='banner-div d-flex align-items-center justify-content-between'>
      <div className="main-logo-section">
        <a href="/home/TU0000">
          <img src='/images/polus.webp' alt="Logo" className="logo" />
        </a>
      </div>
      <div className='banner-title'>
        <span>SecretPass</span>
      </div>
      <div className='profile-label-section align-items-center'>
        {userDetails ? (
          <div className="user-profile d-flex align-items-center">
            <div className='profile-image1'>
              <img height={'20px'} width={'100%'}
                src={userDetails.profile_pic || "/images/profile-user.png"} 
                alt="Profile Pic" 
                className='profile-pic' 
              />
            </div>
            <div className="user-info">
              <span className="user-name">{`${userDetails.first_name || 'Guest'} ${userDetails.last_name || ''}`}</span>
              <span className="user-role">{userDetails.access_type === "1" ? "Admin" : "Employee"}</span>
            </div>
          </div>
        ) : (
          <div className="user-profile d-flex align-items-center">
            <div className='profile-image'>
              <img height={'50px'} width={'100%'} src="/images/profile-user.png" alt="Profile Pic" className='profile-pic' />
            </div>
            <div className="user-info">
              <span className="user-name">Guest</span>
              <span className="user-role">Visitor</span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Navbar;





// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import { useParams } from 'react-router-dom'; // Import useParams
// import '../../styles/Navbar.css'; // Make sure the path is correct
// import { Corporate_url } from '../../Config/Config';

// const Navbar = () => {
//   const { id } = useParams(); // Get id from URL parameters
//   const [userDetails, setUserDetails] = useState(null);

//   useEffect(() => {
//     const fetchUserDetails = async () => {
//       try {
//         const token = localStorage.getItem('token'); // Retrieve the token from local storage
//         const response = await axios.get(`${Corporate_url}/api/user-details/${Number(id)}`, {
//           headers: {
//             'Authorization': `Bearer ${token}`, // Include the token in the Authorization header
//           },
//         });
//         setUserDetails(response.data);
//         localStorage.setItem("access_type", response.data.access_type);
//       } catch (error) {
//         console.error('Error fetching user details:', error);
//       }
//     };

//     if (id) { // Ensure id is defined before fetching
//       fetchUserDetails();
//     }
//   }, [id]); // Add id to the dependency array

//   return (
//     <div className='banner-div d-flex align-items-center justify-content-between'>
//       <div className="main-logo-section">
//         <a href="/home/TU0000">
//           <img src='/images/polus.webp' alt="Logo" className="logo" />
//         </a>
//       </div>
//       <div className='banner-title'>
//         <span>PassSecure</span>
//       </div>
      
//       <div className='profile-label-section align-items-center'>
//         {userDetails && (
//           <div className="user-profile d-flex align-items-center">
//             <div className='profile-image'>
//               {userDetails.profile_pic ? 
//                 <img src={userDetails.profile_pic} alt="Profile Pic" className='profile-pic' /> 
//                 : <img src="/images/profile-user.png" alt="Default Profile Pic" className='profile-pic' />
//               }
//             </div>
//             <div className="user-info">
//               <span className="user-name">{userDetails.first_name} {userDetails.last_name}</span>
//               <span className="user-role">{userDetails.access_type === "1" ? "Admin" : "Employee"}</span>
//             </div>
//           </div>
//         )}
//       </div>
//     </div>
//   );
// };

// export default Navbar;
