
import "../../styles/SendingMessage.css";
import React, { useState, useEffect } from 'react';
import { Row } from 'react-bootstrap';
import api from '../../Api/api';
import { encryptMessage,decryptMessage } from '../../Utils/EncryptionDecryption'; 
import baseUrl from '../../Config/Config';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Select from 'react-select'; 
import { useLocation } from 'react-router-dom';
import { ThreeDots } from "react-loader-spinner";

const userTypes = [
  { value: 'os_user', label: 'OS User' },
  { value: 'system_user', label: 'System User' },
  { value: 'technical_user', label: 'Technical User' },
  { value: 'db_user', label: 'DB User' },
];

const SendingMessage = () => {
  const location = useLocation();
  const { 
    reciever_user,
    reciever_userId, 
    reciever_profilePic, 
    reciever_employeeName, 
    reciever_platform,
    reciever_customer ,
    reciever_userType// Pre-fill customer
  } = location.state || {};

  const data = [
    { name: 'Item 1', value: 4000 },
    { name: 'Item 2', value: 3000 },
    { name: 'Item 3', value: 2000 },
    { name: 'Item 4', value: 2780 },
    { name: 'Item 5', value: 1890 },
    { name: 'Item 6', value: 2390 },
];
  // Decrypt the receiver user
  const decryptedRecieverUser = reciever_user ? decryptMessage(reciever_user) : '';

  // Example of using the decrypted value
  console.log(decryptedRecieverUser);
  
  const [validated, setValidated] = useState(false);
  const [employees, setEmployees] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectedEmployees, setSelectedEmployees] = useState([]);
  const [customer, setCustomer] = useState(reciever_customer || ''); // Pre-fill customer
  const [platform, setPlatform] = useState(reciever_platform || ''); // Ensure this is initialized
  const [userType, setUserType] = useState(reciever_userType ||'');
  const [userId, setUserId] = useState(decryptedRecieverUser|| ''); // Pre-fill userId
  const [password, setPassword] = useState(''); // Pre-fill password
  const [loading, setLoading] = useState(false);
  const [fetchingEmployees, setFetchingEmployees] = useState(false);
  const [employeeRoles, setEmployeeRoles] = useState({});
  const [progress, setProgress] = useState(0);
  const [profilePic, setProfilePic] = useState(reciever_profilePic || '/images/profile-user.png');


  useEffect(() => {
    const fetchEmployees = async () => {
      setFetchingEmployees(true);
      try {
        const token = localStorage.getItem('token');
        const response = await fetch(`${baseUrl}/api/getallusers/`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });

        if (!response.ok) {
          throw new Error('Failed to fetch employees');
        }

        const data = await response.json();
        setEmployees(data);

        const roles = {};
        data.forEach(emp => {
          roles[emp.employeeId] = emp.role;
        });
        setEmployeeRoles(roles);

        if (reciever_userId) {
          const defaultOption = data.find(emp => emp.employeeId === reciever_userId);
          if (defaultOption) {
            setSelectedOptions([{
              value: defaultOption.employeeId,
              label: `${defaultOption.first_name} ${defaultOption.last_name} (${defaultOption.employeeId})`,
              profile_pic: defaultOption.profile_pic || '/images/profile-user.png'
            }]);
            setSelectedEmployees([{
              id: defaultOption.employeeId,
              name: `${defaultOption.first_name} ${defaultOption.last_name}`,
              profile_pic: defaultOption.profile_pic,
              email: defaultOption.email || 'N/A'
            }]);
          }
        }
      } catch (error) {
        console.error('Error fetching employee data:', error);
      } finally {
        setFetchingEmployees(false);
      }
    };

    fetchEmployees();
  }, [reciever_userId]);

  const employeeId = localStorage.getItem('user_id');

  const optionsData = employees
  .filter(emp => emp.employeeId !== employeeId) // Exclude logged-in user
  .map(emp => ({
    value: emp.employeeId,
    label: `${emp.first_name} ${emp.last_name} (${emp.employeeId})`,
    profile_pic: emp.profile_pic || '/images/profile-user.png'
  }));

  const fetchProfilePics = async (data) => {
    const profilePicsPromises = data.map(async (option) => {
      const response = await fetch(`${baseUrl}/api/getprofile/${option.value}`);
      if (!response.ok) {
        throw new Error('Failed to fetch profile picture');
      }
      const userProfile = await response.json();
      return {
        id: option.value,
        name: option.label,
        profile_pic: userProfile.profile_pic || '/images/profile-user.png',
        email: employees.find(emp => emp.employeeId === option.value)?.email || 'N/A'
      };
    });

    return await Promise.all(profilePicsPromises);
  };

  const handleSelectPersonChange = async (data) => {
    setSelectedOptions(data);
    try {
      const fetchedEmployees = await fetchProfilePics(data);
      setSelectedEmployees(fetchedEmployees);
    } catch (error) {
      console.error('Error fetching employee profile pictures:', error);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      setValidated(true);
      return;
    }
    // Get logged-in user's ID
  const employeeId = localStorage.getItem('user_id');

  // Check if the selected employees include the logged-in user
  const selfSelected = selectedOptions.some(option => option.value === employeeId);
  if (selfSelected) {
    toast.error("You cannot send a message to yourself.");
    return;
  }
    setValidated(true); 
    setLoading(true);
    const toastId = toast.loading('Sending messages...');

    try {
      const token = localStorage.getItem('token');
      const employeeId = localStorage.getItem('user_id');
      const encryptedMessage = encryptMessage(platform);
      const encryptedUserId = encryptMessage(userId);  
      const encryptedPassword = encryptMessage(password);
      await Promise.all(selectedOptions.map(async (option) => {
        const response = await api.post(
          `${baseUrl}/api/messages/send`,
          {
            message: encryptedMessage,
            userId: encryptedUserId,
            password: encryptedPassword,
            role: employeeRoles[option.value],
            sender: employeeId,
            recipient: option.value,
            customer,
            platform,
            type: userType
          },
          {
            headers: {
              'Authorization': `Bearer ${token}`,
            },
          }
        );
        if (response.status !== 200) {
          throw new Error('Failed to send message');
        }
      }));
      toast.dismiss(toastId);
      toast.success('Messages sent successfully!');
      resetForm();
    } catch (error) {
      toast.dismiss(toastId);
      console.error('Error sending messages:', error);
      toast.error('Error sending messages');
    } finally {
      setLoading(false);
    }
  };

  const resetForm = () => {
    setCustomer(reciever_customer || ''); // Reset to initial customer value
    setPlatform(reciever_platform || ''); // Reset platform
    setUserId(''); // Reset to initial or empty
    setPassword(''); // Clear password
    setSelectedOptions([]);
    setSelectedEmployees([]);
    setUserType(reciever_userType ||'');
  };

  useEffect(() => {
    const totalFields = 6; 
    let filledFields = 0;
    if (customer) filledFields++;
    if (platform) filledFields++;
    if (userId) filledFields++;
    if (password) filledFields++;
    if (userType) filledFields++;
    if (selectedOptions.length > 0) filledFields++;
    setProgress((filledFields / totalFields) * 100);
  }, [customer, platform, userId, password, userType, selectedOptions]);
 
  return (
    <div className='w-100 mt-5 parent-send'>
      <div className="sendmessage-content">
      <div className="main-2">
        {fetchingEmployees ? (
          <div className="d-flex justify-content-center mt-5 l-animation">
            <span><ThreeDots
              visible={true}
              height="80"
              width="80"
              color="rgb(91, 48, 229)"
              radius="9"
              ariaLabel="three-dots-loading"
              wrapperStyle={{}}
              wrapperClass=""
              /></span>
          </div>
        ) : (
          <>
            <Row className="r1">
              <div className="col-8">
                {/* <div className="head-section">
                  <div className=" s-box">
                    <h1 className="text-center fw-bold">Share Password</h1>
                  </div>
                </div> */}
                <div className='f-box'>
                  <div className="fw-bold text-center head "><h4>Share Password</h4>
                  <br />
                  
                  </div>
                  <Row>
                    <div className="mb-4 t-box text-start">
                      <label className="label-form">Type of User</label>
                      <div id="userType" className="user-type-container">
                        {userTypes.map(option => (
                          <div key={option.value} className="radio-box">
                            <input
                              required
                              className="p-5"
                              type="radio" 
                              id={option.value} 
                              name="userType" 
                              value={option.value} 
                              checked={userType === option.value}
                              onChange={() => setUserType(option.value)} 
                            />
                            <label htmlFor={option.value}>{option.label}</label>
                          </div>
                        ))}
                      </div>
                    </div>
                  </Row>
                  <form noValidate validated={validated} onSubmit={handleSubmit}>
                    <Row>
                      <div className="t-box mb-4 col-6 i-box">
                        <label htmlFor="customerInput" className="label-form">Customer<span ></span>:
                        </label>
                        <input
                          id="customerInput"
                          type="text"
                          placeholder="Enter Customer Name"
                          value={customer}
                          onChange={(e) => setCustomer(e.target.value)}
                        />
                      </div>
                      <div className="col-6 text-start">
                        <label htmlFor="platformInput" className="label-form">System/Platform <span style={{ color: 'red' }}>*</span>:</label>
                        <input
                          id="platformInput"
                          type="text"
                          placeholder="Enter System/Platform Name"
                          value={platform}
                          onChange={(e) => setPlatform(e.target.value)}
                          required
                        />
                      </div>
                    </Row>
                    <Row>
                      <div className="t-box mb-4 i-box col-6 text-start">
                        <label htmlFor="userIdInput" className="label-form">User<span style={{ color: 'red' }}> *</span>:</label>
                        <input
                          style={{ marginRight: "15px" }}
                          id="userIdInput"
                          type="text"
                          placeholder="Enter User "
                          value={userId} // Bind value to state
                          onChange={(e) => setUserId(e.target.value)}
                          required
                        />
                      </div>
                      <div className="col-6 text-start">
                        <label htmlFor="passwordInput" className="label-form">Password <span style={{ color: 'red' }}>*</span>:</label>
                        <input
                          id="passwordInput"
                          type="password"
                          placeholder="Password"
                          value={password} // Bind value to state
                          onChange={(e) => setPassword(e.target.value)}
                          required
                        />
                      </div>
                    </Row>
                    <div className="t-box text-start" style={{width:'95%'}}>
                      <label htmlFor="employeeSelect" className="label-form">Select Employees <span style={{ color: 'red' }}>* </span>:</label>
                      <Select 
                        isMulti
                        isClearable={true}
                        value={selectedOptions}
                        onChange={handleSelectPersonChange} 
                        options={optionsData}
                        className='w-100'
                        styles={{
                          control: (base, state) => ({
                            ...base,
                            borderColor: state.isFocused ?'rgb(228, 219, 255)' : '#f7f4ff',  // Focused border color
                            boxShadow: state.isFocused ? '0 0 0 1px rgb(228, 219, 255)' : 'none', // Optional: add shadow on focus
                            '&:hover': {
                                borderColor: 'rgb(247 244 255)', // Change border color on hover
                            },
                            height: '40px', // Set your desired height here
                            minHeight: '50px',
                            overflow:"hidden" // Optional: Set a minimum height
                
                          }),
                          multiValue: (base) => ({
                            ...base,
                            backgroundColor: '#ccbdff'
                          }),
                          multiValueLabel: (base) => ({
                            ...base,
                            color: 'black'
                          }),
                          option: (base, state) => ({
                            ...base,
                            backgroundColor: state.isFocused ? '#d9caff' : 'white', // Highlight on hover
                            color: state.isSelected ? 'white' : 'black',
                            cursor: 'pointer', // Change cursor to pointer
                            padding: '10px', // Add padding
                        })
                          ,
                          multiValueRemove: (base) => ({
                            ...base,
                            color: 'black',
                            ':hover': {
                              backgroundColor: '#5b30e5',
                              color: 'white'
                            }
                          })
                        }}
                      />
                    </div>
                    <div className="text-end send-button"  ><br />
                      <button className="f-btn-2"  type="submit">Send</button>
                    </div>
                  </form>
                </div>
              </div>
              <div className="col-4 profile-section">
              <div className="p-head"><h5 className="text-center">Selected Employees</h5>
              </div>
                {/* <div className="p-profile"> */}
                <div className="profile-details w-100">
                  {reciever_userId ? (
                    <div className="profile-hd section-highlight mb-4">
                      <div className="profile-img-container">
                        <div className="circle-progress">
                          <div className="circular-progress" style={{ '--progress': `${progress}%` }}></div>
                          <img src={profilePic} alt="profile" className='rounded-image' />
                        </div>
                      </div>
                      <div className="discription">
                        <p className="fw-bold">{reciever_employeeName} <br /> 
                        <span className="fw-normal"></span></p>
                      </div>
                    </div>
                  ) : (
                    selectedEmployees.length > 0 ? (
                      selectedEmployees.map((employee, index) => (
                        <div className={`profile-hd  ${selectedEmployees.length === 1 ? 'single-employee' : ''}`} key={employee.id}>
                          <div className="profile-img-container">
                            <div className="circle-progress">
                              <div className="circular-progress" style={{ '--progress': `${progress}%` }}></div>
                              <img src={employee.profile_pic} alt="profile" className='rounded-image' />
                            </div>
                          </div>
                          <div className="discription">
                           <div className="" style={{height:'15px',whiteSpace: 'nowrap'}}> <p className="fw-bold">{employee.name}</p></div>
                            <div className="role"> <p className="">{employeeRoles[employee.id] || 'Role not assigned'}</p>
                            </div>
                          </div>
                        </div>
                      ))
                    ) : (
                      <div className="default-profile">
                        <div className="profile-img">
                          <div className="circle-progress">
                            <div className="circular-progress" style={{ '--progress': `${progress}%` }}></div>
                            <img src="/images/profile-user.png" alt="default-profile" className='rounded-image' />
                          </div>
                          <div className="discription">
                            <p className="">No Employee Selected</p>
                          </div>                       
                        </div>
                      </div>
                    )
                  )}
                </div>             
              {/* </div> */}
              </div>
            </Row>
          </>
        )}
      </div>
      </div>

      <ToastContainer 
        position="top-center"
        autoClose={1000} 
        hideProgressBar={true}
        closeOnClick
      />
    </div>
  );
};

export default SendingMessage;

// import "../../styles/SendingMessage.css";
// import React, { useState, useEffect } from 'react';
// import { Row } from 'react-bootstrap';
// import api from '../../Api/api';
// import { encryptMessage,decryptMessage } from '../../Utils/EncryptionDecryption'; 
// import baseUrl from '../../Config/Config';
// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
// import Select from 'react-select'; 
// import { useLocation } from 'react-router-dom';
// import {BarChart,CartesianGrid,XAxis,YAxis,Tooltip,Legend,Bar} from 'recharts';
// import { ThreeDots } from "react-loader-spinner";

// const userTypes = [
//   { value: 'os_user', label: 'OS User' },
//   { value: 'system_user', label: 'System User' },
//   { value: 'technical_user', label: 'Technical User' },
//   { value: 'db_user', label: 'DB User' },
// ];

// const SendingMessage = () => {
//   const location = useLocation();
//   const { 
//     reciever_user,
//     reciever_userId, 
//     reciever_profilePic, 
//     reciever_employeeName, 
//     reciever_platform,
//     reciever_customer ,
//     reciever_userType// Pre-fill customer
//   } = location.state || {};

//   const data = [
//     { name: 'Item 1', value: 4000 },
//     { name: 'Item 2', value: 3000 },
//     { name: 'Item 3', value: 2000 },
//     { name: 'Item 4', value: 2780 },
//     { name: 'Item 5', value: 1890 },
//     { name: 'Item 6', value: 2390 },
// ];
//   // Decrypt the receiver user
//   const decryptedRecieverUser = reciever_user ? decryptMessage(reciever_user) : '';

//   // Example of using the decrypted value
//   console.log(decryptedRecieverUser);
  
//   const [validated, setValidated] = useState(false);
//   const [employees, setEmployees] = useState([]);
//   const [selectedOptions, setSelectedOptions] = useState([]);
//   const [selectedEmployees, setSelectedEmployees] = useState([]);
//   const [customer, setCustomer] = useState(reciever_customer || ''); // Pre-fill customer
//   const [platform, setPlatform] = useState(reciever_platform || ''); // Ensure this is initialized
//   const [userType, setUserType] = useState(reciever_userType ||'');
//   const [userId, setUserId] = useState(decryptedRecieverUser|| ''); // Pre-fill userId
//   const [password, setPassword] = useState(''); // Pre-fill password
//   const [loading, setLoading] = useState(false);
//   const [fetchingEmployees, setFetchingEmployees] = useState(false);
//   const [employeeRoles, setEmployeeRoles] = useState({});
//   const [progress, setProgress] = useState(0);
//   const [profilePic, setProfilePic] = useState(reciever_profilePic || '/images/profile-user.png');
//   const [chartData, setChartData] = useState([]);


//   useEffect(() => {
//     const fetchEmployees = async () => {
//       setFetchingEmployees(true);
//       try {
//         const token = localStorage.getItem('token');
//         const response = await fetch(`${baseUrl}/api/getallusers/`, {
//           method: 'GET',
//           headers: {
//             'Authorization': `Bearer ${token}`,
//             'Content-Type': 'application/json',
//           },
//         });

//         if (!response.ok) {
//           throw new Error('Failed to fetch employees');
//         }

//         const data = await response.json();
//         setEmployees(data);

//         const roles = {};
//         data.forEach(emp => {
//           roles[emp.employeeId] = emp.role;
//         });
//         setEmployeeRoles(roles);

//         if (reciever_userId) {
//           const defaultOption = data.find(emp => emp.employeeId === reciever_userId);
//           if (defaultOption) {
//             setSelectedOptions([{
//               value: defaultOption.employeeId,
//               label: `${defaultOption.first_name} ${defaultOption.last_name} (${defaultOption.employeeId})`,
//               profile_pic: defaultOption.profile_pic || '/images/profile-user.png'
//             }]);
//             setSelectedEmployees([{
//               id: defaultOption.employeeId,
//               name: `${defaultOption.first_name} ${defaultOption.last_name}`,
//               profile_pic: defaultOption.profile_pic,
//               email: defaultOption.email || 'N/A'
//             }]);
//           }
//         }
//       } catch (error) {
//         console.error('Error fetching employee data:', error);
//       } finally {
//         setFetchingEmployees(false);
//       }
//     };

//     fetchEmployees();
//   }, [reciever_userId]);

//     // Calculate chart data based on selected employees
//     useEffect(() => {
//       const newChartData = selectedEmployees.map((employee) => ({
//         name: employee.name,
//         value: employeeRoles[employee.id] ? employeeRoles[employee.id].length * 1000 : 0,
//       }));
//       setChartData(newChartData);
//     }, [selectedEmployees, employeeRoles]);
//   const employeeId = localStorage.getItem('user_id');

//   const optionsData = employees
//   .filter(emp => emp.employeeId !== employeeId) // Exclude logged-in user
//   .map(emp => ({
//     value: emp.employeeId,
//     label: `${emp.first_name} ${emp.last_name} (${emp.employeeId})`,
//     profile_pic: emp.profile_pic || '/images/profile-user.png'
//   }));

//   const fetchProfilePics = async (data) => {
//     const profilePicsPromises = data.map(async (option) => {
//       const response = await fetch(`${baseUrl}/api/getprofile/${option.value}`);
//       if (!response.ok) {
//         throw new Error('Failed to fetch profile picture');
//       }
//       const userProfile = await response.json();
//       return {
//         id: option.value,
//         name: option.label,
//         profile_pic: userProfile.profile_pic || '/images/profile-user.png',
//         email: employees.find(emp => emp.employeeId === option.value)?.email || 'N/A'
//       };
//     });

//     return await Promise.all(profilePicsPromises);
//   };

//   const handleSelectPersonChange = async (data) => {
//     setSelectedOptions(data);
//     try {
//       const fetchedEmployees = await fetchProfilePics(data);
//       setSelectedEmployees(fetchedEmployees);
//     } catch (error) {
//       console.error('Error fetching employee profile pictures:', error);
//     }
//   };


//   const handleSubmit = async (event) => {
//     event.preventDefault();
//     const form = event.currentTarget;

//     if (form.checkValidity() === false) {
//       setValidated(true);
//       return;
//     }
//     // Get logged-in user's ID
//   const employeeId = localStorage.getItem('user_id');

//   // Check if the selected employees include the logged-in user
//   const selfSelected = selectedOptions.some(option => option.value === employeeId);
//   if (selfSelected) {
//     toast.error("You cannot send a message to yourself.");
//     return;
//   }
//     setValidated(true); 
//     setLoading(true);
//     const toastId = toast.loading('Sending messages...');

//     try {
//       const token = localStorage.getItem('token');
//       const employeeId = localStorage.getItem('user_id');
//       const encryptedMessage = encryptMessage(platform);
//       const encryptedUserId = encryptMessage(userId);  
//       const encryptedPassword = encryptMessage(password);
//       await Promise.all(selectedOptions.map(async (option) => {
//         const response = await api.post(
//           `${baseUrl}/api/messages/send`,
//           {
//             message: encryptedMessage,
//             userId: encryptedUserId,
//             password: encryptedPassword,
//             role: employeeRoles[option.value],
//             sender: employeeId,
//             recipient: option.value,
//             customer,
//             platform,
//             type: userType
//           },
//           {
//             headers: {
//               'Authorization': `Bearer ${token}`,
//             },
//           }
//         );
//         if (response.status !== 200) {
//           throw new Error('Failed to send message');
//         }
//       }));
//       toast.dismiss(toastId);
//       toast.success('Messages sent successfully!');
//       resetForm();
//     } catch (error) {
//       toast.dismiss(toastId);
//       console.error('Error sending messages:', error);
//       toast.error('Error sending messages');
//     } finally {
//       setLoading(false);
//     }
//   };

//   const resetForm = () => {
//     setCustomer(reciever_customer || ''); // Reset to initial customer value
//     setPlatform(reciever_platform || ''); // Reset platform
//     setUserId(''); // Reset to initial or empty
//     setPassword(''); // Clear password
//     setSelectedOptions([]);
//     setSelectedEmployees([]);
//     setUserType(reciever_userType ||'');
//   };

//   useEffect(() => {
//     const totalFields = 6; 
//     let filledFields = 0;
//     if (customer) filledFields++;
//     if (platform) filledFields++;
//     if (userId) filledFields++;
//     if (password) filledFields++;
//     if (userType) filledFields++;
//     if (selectedOptions.length > 0) filledFields++;
//     setProgress((filledFields / totalFields) * 100);
//   }, [customer, platform, userId, password, userType, selectedOptions]);
 
//   return (
//     <div className='w-100'>
//       <div className="main-2">
//         {fetchingEmployees ? (
//           <div className="d-flex justify-content-center mt-5 l-animation">
//             <span><ThreeDots
//               visible={true}
//               height="80"
//               width="80"
//               color="rgb(91, 48, 229)"
//               radius="9"
//               ariaLabel="three-dots-loading"
//               wrapperStyle={{}}
//               wrapperClass=""
//               /></span>
//           </div>
//         ) : (
//           <>
//             <Row className="r1">
//               <div className="col-8">
//                 <div className="head-section">
//                   <div className=" s-box">
//                     <h1 className="text-center fw-bold">Share Password</h1>
//                   </div>
//                 </div>
//                 <br />
//                 <br />
//                 <div className='f-box'>
//                   <Row>
//                     <div className="mb-4 t-box text-start">
//                       <label className="label-form">Type of User</label>
//                       <div id="userType" className="user-type-container">
//                         {userTypes.map(option => (
//                           <div key={option.value} className="radio-box">
//                             <input
//                               required
//                               className="p-5"
//                               type="radio" 
//                               id={option.value} 
//                               name="userType" 
//                               value={option.value} 
//                               checked={userType === option.value}
//                               onChange={() => setUserType(option.value)} 
//                             />
//                             <label htmlFor={option.value}>{option.label}</label>
//                           </div>
//                         ))}
//                       </div>
//                     </div>
//                   </Row>
//                   <form noValidate validated={validated} onSubmit={handleSubmit}>
//                     <Row>
//                       <div className="t-box mb-4 col-6 i-box">
//                         <label htmlFor="customerInput" className="label-form">Customer<span style={{ color: 'red' }}>*</span>:</label>
//                         <input
//                           id="customerInput"
//                           type="text"
//                           placeholder="Enter Customer Name"
//                           value={customer}
//                           onChange={(e) => setCustomer(e.target.value)}
//                         />
//                       </div>
//                       <div className="col-6 text-start">
//                         <label htmlFor="platformInput" className="label-form">System/Platform <span style={{ color: 'red' }}>*</span>:</label>
//                         <input
//                           id="platformInput"
//                           type="text"
//                           placeholder="Enter System/Platform Name"
//                           value={platform}
//                           onChange={(e) => setPlatform(e.target.value)}
//                           required
//                         />
//                       </div>
//                     </Row>
//                     <Row>
//                       <div className="t-box mb-4 i-box col-6 text-start">
//                         <label htmlFor="userIdInput" className="label-form">UserId<span style={{ color: 'red' }}>*</span>:</label>
//                         <input
//                           style={{ marginRight: "15px" }}
//                           id="userIdInput"
//                           type="text"
//                           placeholder="Enter User "
//                           value={userId} // Bind value to state
//                           onChange={(e) => setUserId(e.target.value)}
//                           required
//                         />
//                       </div>
//                       <div className="col-6 text-start">
//                         <label htmlFor="passwordInput" className="label-form">Password <span style={{ color: 'red' }}>*</span>:</label>
//                         <input
//                           id="passwordInput"
//                           type="password"
//                           placeholder="Password"
//                           value={password} // Bind value to state
//                           onChange={(e) => setPassword(e.target.value)}
//                           required
//                         />
//                       </div>
//                     </Row>
//                     <div className="t-box text-start" style={{width:"96%"}}>
//                       <label htmlFor="employeeSelect" className="label-form">Select Employees <span style={{ color: 'red' }}>* </span>:</label>
//                       <Select 
//                         isMulti
//                         isClearable={true}
//                         value={selectedOptions}
//                         onChange={handleSelectPersonChange} 
//                         options={optionsData}
//                         className='w-100'
//                         styles={{
//                           control: (base, state) => ({
//                             ...base,
//                             borderColor: state.isFocused ? 'blue' : 'lightgray',
//                             boxShadow: state.isFocused ? '0 0 0 1px blue' : 'none',
//                             '&:hover': {
//                               borderColor: 'blue'
//                             }
//                           }),
//                           multiValue: (base) => ({
//                             ...base,
//                             backgroundColor: '#ccbdff'
//                           }),
//                           multiValueLabel: (base) => ({
//                             ...base,
//                             color: 'black'
//                           }),
//                           option: (base, state) => ({
//                             ...base,
//                             backgroundColor: state.isFocused ? '#ccbdff' : 'white', // Highlight on hover
//                             color: state.isSelected ? 'white' : 'black',
//                             cursor: 'pointer', // Change cursor to pointer
//                             padding: '10px', // Add padding
//                         })
//                           ,
//                           multiValueRemove: (base) => ({
//                             ...base,
//                             color: 'black',
//                             ':hover': {
//                               backgroundColor: '#5b30e5',
//                               color: 'white'
//                             }
//                           })
//                         }}
//                       />
//                     </div>
//                     <div className="text-end" style={{padding: '0 22px'}} ><br />
//                       <button className="f-btn-2"  type="submit">Send</button>
//                     </div>
//                   </form>
//                 </div>
//               </div>
//               <div className="col-4 profile-section">
//               <div className="p-profile">
//                 <h5 className="text-start">Statistic</h5>
//                 <div className="profile-details w-100">
//                   {reciever_userId ? (
//                     <div className="profile-hd section-highlight mb-4">
//                       <div className="profile-img-container">
//                         <div className="circle-progress">
//                           <div className="circular-progress" style={{ '--progress': `${progress}%` }}></div>
//                           <img src={profilePic} alt="profile" className='rounded-image' />
//                         </div>
//                       </div>
//                       <div className="discription">
//                         <p className="fw-bold">{reciever_employeeName} <br /> 
//                         <span className="fw-normal"></span></p>
//                       </div>
//                     </div>
//                   ) : (
//                     selectedEmployees.length > 0 ? (
//                       selectedEmployees.map((employee, index) => (
//                         <div className={`profile-hd  ${selectedEmployees.length === 1 ? 'single-employee' : ''}`} key={employee.id}>
//                           <div className="profile-img-container">
//                             <div className="circle-progress">
//                               <div className="circular-progress" style={{ '--progress': `${progress}%` }}></div>
//                               <img src={employee.profile_pic} alt="profile" className='rounded-image' />
//                             </div>
//                           </div>
//                           <div className="discription">
//                            <div className="" style={{height:'15px'}}> <p className="fw-bold">{employee.name}</p></div>
//                             <div className="role"> <p className="">{employeeRoles[employee.id] || 'Role not assigned'}</p>
//                             </div>
//                           </div>
//                         </div>
//                       ))
//                     ) : (
//                       <div className="default-profile">
//                         <div className="profile-img">
//                           <div className="circle-progress">
//                             <div className="circular-progress" style={{ '--progress': `${progress}%` }}></div>
//                             <img src="/images/profile-user.png" alt="default-profile" className='rounded-image' />
//                           </div>
//                           <div className="discription">
//                             <p className="">No Employee Selected</p>
//                           </div>                       
//                         </div>
//                       </div>
//                     )
//                   )}
//                 </div>             
//               </div>
//               <div className="graph">
//                 <div className="chart">
//                 <BarChart width={350} height={150} data={chartData.length > 0 ? chartData : [{ name: 'No Data', value: 0 }]}>
//                   <CartesianGrid strokeDasharray="3 3" />
//                   <XAxis dataKey="name" />
//                   <YAxis />
//                   <Tooltip />
//                   <Legend />
//                   <Bar dataKey="value" fill="#8884d8" />
//                 </BarChart>
//                 </div>
//               </div>
//               </div>
//             </Row>
//           </>
//         )}
//       </div>

//       <ToastContainer 
//         position="top-center"
//         autoClose={1000} 
//         hideProgressBar={true}
//         closeOnClick
//       />
//     </div>
//   );
// };

// export default SendingMessage;
              {/* <div className="graph">
                <div className="d-flex">
                <BarChart width={350} height={150} data={data}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="pv" fill="#8884d8" />
                <Bar dataKey="uv" fill="#82ca9d" />
              </BarChart>
                </div>
              </div> */}
